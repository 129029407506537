import React from 'react';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Layout, Avatar, Button } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import AuthService from 'services/AuthService';
import ROUTE_PAGES from 'routes/Routes';

const { Header, Content } = Layout;

const MainLayout = ({ children }) => {
  const navigate = useNavigate();
  const credentials = AuthService.getCredentials();

  const handleLogout = () => {
    AuthService.fetchLogout();
    navigate('/login', { replace: true });
  };

  return (
    <Layout>
      <Header style={{ padding: 0, height: 50 }}>
        <div className="flex items-center justify-between h-full">
          <div className="flex items-center">
            <div className="relative w-[150px] h-[45px] overflow-hidden">
              <Link to="/">
                <img
                  className="w-full h-[140px] absolute top-[-40px] left-0"
                  src="/images/logo_white.png"
                  alt="Logo"
                />
              </Link>
            </div>
          </div>
          {credentials && (
            <div className="flex mr-4 items-center text-white h-full">
              <Link
                className="flex items-center text-white"
                to={ROUTE_PAGES.PROFILE}
              >
                <p className="m-0 font-semibold mr-1">
                  Hi, {credentials?.name}
                </p>
                <Avatar size={30} icon={<UserOutlined />} />
              </Link>
              <Button
                onClick={handleLogout}
                className="ml-3 text-white hover:!text-white"
                type="text"
                icon={<LogoutOutlined />}
              />
            </div>
          )}
        </div>
      </Header>
      <Content style={{ padding: 24, backgroundColor: '#f8fafc' }}>
        {children}
      </Content>
    </Layout>
  );
};

export default MainLayout;
