import useSWR from 'swr';
import ProductService from 'services/ProductService';

const fetcher = (params) => {
  const data = new URLSearchParams(params);
  const queryParams = Object.fromEntries(data.entries());
  const items = queryParams.items ? queryParams.items.split(',') : [];
  if (items.length === 0) {
    return Promise.resolve({ data: [] });
  }
  return ProductService.fetchProducts(queryParams);
};

const useProducts = ({
  page = 1,
  ids = [],
  items = [],
  price = '',
  limit = 20,
  order = '',
  sort = '',
  district = '',
  term = '',
  specifications = '',
}) => {
  const { data, isLoading, isValidating, ...rest } = useSWR(
    `key=products&page=${page || 1}&price=${
      price ?? ''
    }&term=${term}&limit=${limit}&ids=${ids ? ids.join(',') : ''}&items=${
      items ? items.join(',') : ''
    }&order=${order}&sort=${sort}&district=${district}&specifications=${specifications}`,
    fetcher,
    { revalidateOnFocus: false }
  );

  return {
    data: data ? data.data : [],
    isLoading,
    isValidating,
    pagination:
      data && data.total
        ? {
            total: data.total,
            hasNextPage: data.hasNextPage,
            hasPrevPage: data.hasPrevPage,
            page: data.page,
            totalPages: data.totalPages,
            limit: data.limit,
          }
        : null,
    ...rest,
  };
};

export default useProducts;
