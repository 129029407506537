import React, { useState } from 'react';
import { object, string } from 'yup';
import { Formik } from 'formik';
import { Button } from 'antd';
import TextInput from 'components/FormControls/TextInput';
import AuthService from 'services/AuthService';
import { Link, useNavigate } from 'react-router-dom';
import ROUTE_PAGES from 'routes/Routes';
import AuthLayout from 'components/AuthLayout';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = object({
  email: string().required('Dữ liệu bắt buộc').email('Email không đúng'),
  password: string().required('Dữ liệu bắt buộc'),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await AuthService.fetchLogin(values);
      navigate(ROUTE_PAGES.HOME);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout title="ĐĂNG NHẬP ĐỂ TIẾP TỤC">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          touched,
          errors,
        }) => {
          return (
            <div>
              <div className="w-full mb-3">
                <TextInput
                  required
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.email}
                  error={errors.email}
                  value={values.email}
                  name="email"
                />
              </div>
              <div className="w-full mb-5">
                <TextInput
                  type="password"
                  required
                  label="Mật khẩu"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.password}
                  error={errors.password}
                  value={values.password}
                  name="password"
                />
              </div>
              <Button
                loading={isLoading}
                onClick={handleSubmit}
                block
                type="primary"
              >
                ĐĂNG NHẬP
              </Button>

              <Link
                className="text-center no-underline w-full block text-black mt-3 text-sm"
                to="/forgot-password"
              >
                QUÊN MẬT KHẨU
              </Link>
            </div>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default LoginPage;
