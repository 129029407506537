import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/AuthService';
import ROUTE_PAGES from './Routes';
import AppProvider from 'context/AppContext';

const GuardRouter = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const credentials = AuthService.getCredentials();
    if (!credentials) {
      navigate(ROUTE_PAGES.LOGIN, { replace: true });
    }
  }, [navigate]);

  return <AppProvider>{children}</AppProvider>;
};

export default GuardRouter;
