import React, { useState } from 'react';
import { object, string } from 'yup';
import { Formik } from 'formik';
import { Button } from 'antd';
import TextInput from 'components/FormControls/TextInput';
import AuthService from 'services/AuthService';
import AuthLayout from 'components/AuthLayout';
import { useNotify } from 'components/NoticationPrivider';

const initialValues = {
  email: '',
};

const validationSchema = object({
  email: string().required('Dữ liệu bắt buộc').email('Email không đúng'),
});

const ForgotPasswordPage = () => {
  const notify = useNotify();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await AuthService.fetchForgotPassword(values);
      notify.success({
        message: 'Thành công',
        description: response?.message,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout title="NHẬP EMAIL CỦA BẠN">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          touched,
          errors,
        }) => {
          return (
            <div>
              <div className="w-full mb-3">
                <TextInput
                  required
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.email}
                  error={errors.email}
                  value={values.email}
                  name="email"
                />
              </div>
              <Button
                loading={isLoading}
                onClick={handleSubmit}
                block
                type="primary"
              >
                XÁC NHẬN
              </Button>
            </div>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
