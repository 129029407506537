import { createContext, useContext } from 'react';
import { notification } from 'antd';

const NoticationContext = createContext({ toast: {} });

const useAntdNotification = () => {
  const [toast, contextHolder] = notification.useNotification({ maxCount: 1 });
  return { toast, contextHolder };
};

export const useNotify = () => {
  const toast = useContext(NoticationContext);
  return {
    success: ({ message, description }) =>
      toast.success({
        message,
        description,
        placement: 'bottomRight',
      }),
    error: ({ message, description }) =>
      toast.error({
        message,
        description,
        placement: 'bottomRight',
      }),
  };
};

const NoticationPrivider = ({ children }) => {
  const { toast, contextHolder } = useAntdNotification();

  return (
    <>
      {contextHolder}
      <NoticationContext.Provider value={toast}>
        {children}
      </NoticationContext.Provider>
    </>
  );
};

export default NoticationPrivider;
