import React from 'react';
import { Input as AntdInput, InputNumber } from 'antd';
import Label from './Label';
import ValidationField from './ValidationField';

const Input = ({ inputType = 'text', ...props }) => {
  if (inputType === 'password') {
    return <AntdInput.Password {...props} />;
  }
  if (inputType === 'number') {
    return <InputNumber {...props} className="w-full" />;
  }
  if (inputType === 'currency') {
    return (
      <InputNumber
        className="w-full"
        onBlur={props.onBlur}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        formatter={(value) => {
          return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }}
        parser={(value) => {
          return value.replace(/\$\s?|(,*)/g, '');
        }}
      />
    );
  }
  return <AntdInput {...props} />;
};

const TextInput = ({
  type = 'text',
  touched,
  error,
  name,
  value,
  onBlur,
  onChange,
  label,
  required = false,
  placeholder,
  disabled = false,
  ...props
}) => {
  const hasError = touched && error;
  return (
    <div className="w-full">
      {label && <Label name={name} label={label} required={required} />}
      <ValidationField touched={touched} error={error}>
        <Input
          status={hasError ? 'error' : null}
          disabled={disabled}
          placeholder={placeholder ?? `Nhập ${label.toLowerCase()}`}
          inputType={type}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          {...props}
        />
      </ValidationField>
    </div>
  );
};

export default TextInput;
