import React from 'react';

const AuthLayout = ({ title, children }) => {
  return (
    <div
      className="h-screen flex items-center bg-gradient-dark"
      style={{ fontFamily: 'SFCompact' }}
    >
      <div className="w-[70%] max-w-[450px] mx-auto bg-white p-5 shadow-lg border rounded-sm">
        <div className="relative w-[180px] h-[80px] overflow-hidden mx-auto">
          <img
            className="w-full h-[180px] absolute top-[-50px] left-0"
            src="/images/logo_white.png"
            alt="Logo"
          />
        </div>
        <p className="text-center text-lg mt-0 font-medium">{title}</p>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default React.memo(AuthLayout);
