import HttpService from './HttpService';

const fetchLogin = async (payload) => {
  const { data } = await HttpService.Post(
    '/authentication/login/affiliate',
    payload
  );
  localStorage.setItem('auth', JSON.stringify(data));
  return data;
};

const fetchForgotPassword = async (payload) => {
  return await HttpService.Post(
    '/authentication/forgot-password/affiliate',
    payload
  );
};

const fetchResetPassword = async (payload) => {
  return await HttpService.Post(
    '/authentication/reset-password/affiliate',
    payload
  );
};

const fetchLogout = () => {
  localStorage.setItem('auth', '');
};

const fetchChangePassword = async (payload) => {
  return await HttpService.Post(
    '/authentication/change-password/affiliate',
    payload
  );
};

const getCredentials = () => {
  try {
    return JSON.parse(localStorage.getItem('auth'));
  } catch (error) {
    return null;
  }
};

const AuthService = {
  fetchLogin,
  fetchLogout,
  fetchChangePassword,
  fetchForgotPassword,
  fetchResetPassword,
  getCredentials,
};

export default AuthService;
