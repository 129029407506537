import { createContext, useContext } from 'react';
import useSWR from 'swr';
import AffiliateService from 'services/AffiliateService';
import { useNavigate } from 'react-router-dom';
import ROUTE_PAGES from 'routes/Routes';
import AuthService from 'services/AuthService';

const AppContext = createContext({});

const fetcher = async () => {
  return await AffiliateService.fetchGetProfile();
};

const useProfile = () => {
  const navigate = useNavigate();
  return useSWR(`get-profile`, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onError: () => {
      AuthService.fetchLogout();
      navigate(ROUTE_PAGES.LOGIN, { replace: true });
    },
  });
};

export const useAppContext = () => {
  const data = useContext(AppContext);
  return data;
};

const AppProvider = ({ children }) => {
  const { data } = useProfile();

  return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
};

export default AppProvider;
