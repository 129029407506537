import axios from 'axios';
import Constants from 'config/Constants';
import flatten from 'lodash/flatten';
import { notification } from 'antd';
import shared from 'shared';

let store = {};
// How can I use the Redux store in non-component files?
// https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
// Fix the issue: https://github.com/reduxjs/redux-toolkit/issues/167
const injectStore = (_store) => {
  store = _store;
};

const getErrorMessage = (error) => {
  let msg = Constants.DEFAULT_ERROR_MESSAGE;
  if (error.response && typeof error.response.data === 'string') {
    msg = error.response.data;
  }
  if (error.response && error.response.data) {
    if (
      error.response.data.error &&
      typeof error.response.data.error === 'string'
    ) {
      msg = error.response.data.error;
    }
    if (error.response.data.message) {
      msg = error.response.data.message;
    }
    if (error.response.data.messages) {
      msg = error.response.data.messages;
    }
    if (typeof error.response.data.errors === 'object') {
      msg = flatten(Object.values(error.response.data.errors)).join('\r\n');
    }
  }
  return msg;
};

const getCredentials = () => {
  try {
    return JSON.parse(localStorage.getItem('auth'));
  } catch (error) {
    return null;
  }
};

export const HttpHelper = {
  getErrorMessage,
  getCredentials,
  injectStore,
};

const http = axios.create({
  baseURL: Constants.API_URL,
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log(error)
    // if (error.response?.status === 401) {
    //   return handleRefreshToken(error.config);
    // }
    if (error.message) {
      const message = getErrorMessage(error);
      error.message = message;
      notification.error({
        message: 'Lỗi',
        description: message,
        placement: 'bottomRight',
      });
    }
    return Promise.reject(error);
  }
);

const createAxiosConfig = (config = {}) => {
  const credentials = getCredentials();
  let AxiosConfig = {
    ...config,
    dialogType: 'Dialog',
    headers: {
      ...config.headers,
    },
  };

  if (credentials && credentials.accessToken) {
    AxiosConfig = {
      ...AxiosConfig,
      headers: {
        ...AxiosConfig.headers,
        Authorization: `Bearer ${credentials.accessToken} ${shared.Constants.ROLE_TYPES.AFFILIATE}`,
      },
    };
  }
  return AxiosConfig;
};
export default class HttpService {
  static async Get(url, config = {}) {
    return await http
      .get(url, createAxiosConfig(config))
      .then((res) => res.data);
  }

  static async Post(url, data, config = {}) {
    return await http
      .post(url, data, createAxiosConfig(config))
      .then((res) => res.data);
  }

  static async Put(url, data, config = {}) {
    return await http
      .put(url, data, createAxiosConfig(config))
      .then((res) => res.data);
  }

  static async Delete(url, config = {}) {
    return await http
      .delete(url, createAxiosConfig(config))
      .then((res) => res.data);
  }
}
