import HttpService from './HttpService';

async function fetchProducts({
  page = 1,
  limit = 20,
  price,
  keyword,
  order = 'desc',
  sort = 'updatedAt',
  term,
  district,
  specifications,
  ids,
  items,
}) {
  const filter = order && sort ? `${sort},${order}` : '';
  return await HttpService.Get('/products', {
    params: {
      page: parseInt(page) || 1,
      limit: parseInt(limit) || 20,
      price,
      keyword: keyword || '',
      order: filter,
      term: term ?? null,
      district: district ?? null,
      specifications: specifications ?? null,
      ids: ids ?? null,
      items: items ?? null,
    },
  });
}

const ProductService = {
  fetchProducts,
};

export default ProductService;
